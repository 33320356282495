import {
	anketo,
	baccarapp,
	bewking,
	cornermail,
	kabaljero,
	moogle,
	sakuravan,
} from "@/assets";
import { StaticImageData } from "next/image";

export type Tag = {
	name: string;
	color: "blue" | "green" | "yellow" | "orange";
};

export type Project = {
	id: string;
	name: string;
	description: string;
	tags: Tag[];
	image: string | StaticImageData;
	source_code_link?: string;
	deployed_site_link?: string;
	video_demo_link?: string;
};

export const projects: Project[] = [
	{
		id: crypto.randomUUID(),
		name: "CornerMail",
		description:
			"CornerMail, a dynamic web application revolutionizing email campaign management. Powered by Next.js 14 and TypeScript, this platform offers seamless email blasting, personalized templates, and robust recipient management through CSV/XLSX uploads. Gain real-time insights with email tracking and analytics, ensuring effective communication. ",
		tags: [
			{
				name: "next.js",
				color: "blue",
			},
			{
				name: "postgresql",
				color: "green",
			},
			{
				name: "TailwindCSS",
				color: "yellow",
			},
			{
				name: "zustand",
				color: "orange",
			},
		],
		image: cornermail,
		source_code_link: "https://github.com/andasan/cornermail_playground",
		deployed_site_link: "https://cornermail-playground.vercel.app",
	},
	{
		id: crypto.randomUUID(),
		name: "Kabaljero",
		description:
			"Kabaljero Canada Immigration Consulting. This website, meticulously crafted using Next.js, embodies the essence of professionalism and integrity in Canadian immigration consulting. Offering a seamless user experience, it features a comprehensive range of services, client testimonials, transparent pricing, and an interactive contact platform.",
		tags: [
			{
				name: "next.js",
				color: "blue",
			},
			{
				name: "tailwindCSS",
				color: "yellow",
			},
			{
				name: "contentful",
				color: "orange",
			},
		],
		image: kabaljero,
		deployed_site_link: "https://kabaljero.vercel.app/",
	},
	{
		id: crypto.randomUUID(),
		name: "Baccarapp",
		description:
			"Developed a Baccarat Scoreboard App using Tauri, a versatile framework for building desktop applications with web technologies. The app provides a user-friendly interface for tracking Baccarat game scores, offering efficient keyboard commands for various in-game actions.",
		tags: [
			{
				name: "react.js",
				color: "blue",
			},
			{
				name: "tauri",
				color: "green",
			},
			{
				name: "tailwindCSS",
				color: "yellow",
			},
			{
				name: "zustand",
				color: "orange",
			},
		],
		image: baccarapp,
		video_demo_link: "https://youtu.be/PS4S29_POK0",
	},
	{
		id: crypto.randomUUID(),
		name: "Sakura Van",
		description:
			"This React application utilizes Mapbox and Supabase to create an interactive map that allows users to locate cherry blossoms around Vancouver. The app retrieves location data from Supabase, a cloud-hosted database, and displays it on a Mapbox map. Users can view cherry blossom locations and information by clicking on markers on the map.",
		tags: [
			{
				name: "react",
				color: "blue",
			},
			{
				name: "graphql",
				color: "green",
			},
			{
				name: "mapbox",
				color: "yellow",
			},
		],
		image: sakuravan,
		source_code_link: "https://github.com/andasan/shin-sakura-van",
		deployed_site_link: "https://sakura-van.vercel.app/",
	},
	{
		id: crypto.randomUUID(),
		name: "Boxjump",
		description:
			"Boxjump is a simple game built with Vanilla JS and Canvas. The game features a box that jumps over obstacles, and the player must avoid the obstacles to keep the game going. The game is designed to be simple and fun, with a minimalist aesthetic. It is a great example of a game that can be built with basic web technologies.",
		tags: [
			{
				name: "Vanilla JS",
				color: "blue",
			},
			{
				name: "Canvas",
				color: "yellow",
			},
		],
		image:
			"https://raw.githubusercontent.com/andasan/boxjump/master/assets/img/ss1.png",
		deployed_site_link: "/projects/boxjump",
		source_code_link: "https://github.com/andasan/boxjump",
	},
	{
		id: crypto.randomUUID(),
		name: "Moogle",
		description:
			"This project is a web application built with React that allows users to search for Wikipedia articles through a user-friendly interface that replicates the look and feel of Google search. The app retrieves data from the Wikipedia API based on the user's search query and displays the resulting articles in a clear and organized manner.",
		tags: [
			{
				name: "react",
				color: "blue",
			},
			{
				name: "react-query",
				color: "green",
			},
			{
				name: "storybook",
				color: "yellow",
			},
		],
		image: moogle,
		source_code_link: "https://github.com/andasan/moogle",
		deployed_site_link: "https://moogle-bay.vercel.app/",
	},
	{
		id: crypto.randomUUID(),
		name: "Bewking",
		description:
			"A presentation booking application made with React that I made for my students at Cornerstone Tech Inc. The app allows students to book a time slot for their presentations, and the admin can view the bookings and approve them. The app uses Supabase for the database, and Lottie for the animations.",
		tags: [
			{
				name: "react",
				color: "blue",
			},
			{
				name: "supabase",
				color: "green",
			},
			{
				name: "lottie",
				color: "yellow",
			},
		],
		image: bewking,
		source_code_link: "https://github.com/andasan/bewking",
		deployed_site_link: "https://bewking.vercel.app",
	},
	{
		id: crypto.randomUUID(),
		name: "Anketo",
		description:
			"Anketo is a polling application built with T3 app (TypeScript, TailwindCSS and tRPC) and uses Planetscale for the database. The app allows users to create polls and vote on them. I made this web application to test out the T3 stack and to learn more about Planetscale.",
		tags: [
			{
				name: "T3",
				color: "blue",
			},
			{
				name: "planetscale",
				color: "green",
			},
			{
				name: "prisma",
				color: "yellow",
			},
		],
		image: anketo,
		source_code_link: "https://github.com/andasan/anketo",
		deployed_site_link: "https://anketo.vercel.app",
	},
];

import { cn } from "@/utils";
import { motion } from "framer-motion";
import Image from "next/image";
import { Tilt } from "react-next-tilt";

import { github, video, webicon } from "@/assets";
import SectionWrapper from "@/components/modules/wrapper/SectionWrapper";
import { projects, projectsIntro } from "@/constants";
import type { Project } from "@/constants";
import { useComponentOnScreen } from "@/utils/hooks";
import { fadeIn, textVariant } from "@/utils/motion";
import { styles } from "@/utils/styles";
import { PinContainer } from "@/components/modules/3d-pin";

type ProjectCardProps = Project & { index: number };

const ProjectCard = ({
	index,
	name,
	description,
	tags,
	image,
	source_code_link,
	deployed_site_link,
	video_demo_link,
}: ProjectCardProps) => {
	return (
		<PinContainer
			title={deployed_site_link}
			href={deployed_site_link}
			github={source_code_link}
			video={video_demo_link}
			description={description}
			tags={tags}
		>
			<div className="flex basis-full flex-col tracking-tight text-slate-100/50 lg:w-[360px] w-full flex-1 h-[20rem]  ">

				<h3 className="text-white font-bold text-[24px]">{name}</h3>
				<div className="flex flex-1 w-full rounded-lg mt-4 overflow-hidden">
					<Image
						src={image}
						alt={name}
						className="w-full h-full object-cover rounded-2xl"
						width={500}
						height={300}
					/>
				</div>
			</div>
		</PinContainer>
	);
};

const Works = () => {
	const ref = useComponentOnScreen("works");
	return (
		<section id="works" ref={ref}>
			<div className="rounded-xl bg-gray-900 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
				<motion.div variants={textVariant()}>
					<p className={styles.sectionSubText}>My works</p>
					<h2 className={styles.sectionHeadText}>Projects</h2>
				</motion.div>

				<motion.div
					variants={fadeIn("", "", 0.1, 1)}
					// className="mt-3 text-secondary text-[17px] max-w-3xl leading-[30px]"
					className={cn(
						styles.paragraphText,
						"mt-4 leading-[30px]"
					)}
				>
					{projectsIntro}
				</motion.div>
			</div>

			<div className="lg:mt-20 mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 sm:justify-center">
				{projects.map((project, index) => (
					<ProjectCard key={project.id} {...project} index={index} />
				))}
			</div>
		</section>
	);
};

export default SectionWrapper(Works, "works");
